<template>
  <div class="main-body">
    <div class="company">
      <banner :titleId="titleId" ref="banner" />
    </div>
    <!-- 跳转 -->
    <div class="main-children__body" v-if="nodeId[titleId]">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['nodeId']),
    titleId(){
      return this.$route.query.titleId
    },
  },
};
</script>
<style lang="less" scoped>
// 子路由的内容
.main-children__body {
  width: 1200px;
  min-height: 300px;
  padding: 20px 0;
  box-sizing: border-box;
  margin: 0 auto;
}

</style>
